import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import "./Hero.css";
import Form from "../Form/Form";
import Left from "./Left";
import ClientReviews from "../Service/ClientReviews";
import { useState } from "react";
// import warehouses from "../Assets/services/warehouse.webp";
import unloading from "../Assets/services/corporate-relocation.webp";
import loading from "../Assets/services/Domestic-Shifting.webp";
import OfficeShifting from "../Assets/services/office-relocation-services.webp";
import hour from "../Assets/services/bike-and-Car-transportation.webp";
import packing from "../Assets/services/local-shifting.webp";
import { Link } from "react-router-dom";
const Hero = () => {
  const [activeTab, setActiveTab] = useState(1);
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const location = useLocation();
  const localHouseholdRef = useRef(null);

  useEffect(() => {
    if (location.hash === "#Local-Household" && localHouseholdRef.current) {
      localHouseholdRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  const reviews = [
    {
      author: "Hiii Frnds",
      content: `Many more packers and movers in Noida. When I chose NMP Packers and movers, I felt very good. I directly talked to the branch manager and asked everything; they gave me a proper response. About negative reviews, I asked clearly, most are fake reviews.`,
    },
    {
      author: "SUMIT PANWAR",
      content: `Outstanding and superior service supplier. If you don't think what I say is true, try using NMP Packers and movers packers at least once in your life. After that, you keep using their services for your needs. Regards, group`,
    },
    {
      author: "ANUBHAV SHARMA",
      content:
        "Best service. I have recently shifted from Noida to Palacode, Tamil Nadu. Amazing service. Thanks, NMP Packers and movers.",
    },
    {
      author: "MAHESH V",
      content: `greatest business. My friend has already recommended NMP Packers and movers Packers to me when I'm looking for reliable movers and packers in Noida. I decided to utilize this packer and mover after receiving the best services possible from them.`,
    },
    {
      author: "RAVI TANDON",
      content:
        "Nice work. Thanks for support and best shifting. Thank you very much.",
    },
    {
      author: "ARIFA SULTANA",
      content:
        "Excellent customer service. Only one word… Safe secure and full responsibility only single name. NMP Packers and movers packers. Thank you very much.",
    },
    {
      author: "SHWETA TRIPATHI",
      content:
        "Noida to Noida. Thank you NMP Packers and movers packers for helping me to relocate from Noida to Noida. I was very much nervous about shifting but you did very well. Keep it up.",
    },
    {
      author: "RAKESH SHARMA",
      content:
        "Safe shifting. Thanks Suraj. We have shifted from Noida to Imphal. We got our goods safe and secure.",
    },
    {
      author: "RANJINI",
      content: "Good service little costly.",
    },
    {
      author: "SUBHAM VERMA",
      content:
        "Best service. I have recently shifted from Noida to Palacode, Tamil Nadu. Amazing service. Thanks, NMP Packers and movers.",
    },
    {
      author: "RAMYA D",
      content:
        "Time to time delivery. My bike shifted by NMP Packers and movers. Timely delivery. No any damages. Thank you sir… sure I will refer u.",
    },
    {
      author: "ADITYA",
      content:
        "Nice work. Thanks for support and best shifting. Thank you very NMP Packers and movers packers.",
    },
  ];

  return (
    <>
      <section>
        <div className="main-hero">
          <div className="container">
            <div className="row">
              <div className="col-md-7 order-md-1  left-column">
                <Left activeTab={activeTab} setActiveTab={setActiveTab} />
              </div>
              <div className="col-md-5 order-md-1  form-column">
                <Form activeTab={activeTab} setActiveTab={setActiveTab} />
              </div>
            </div>
          </div>

          <div className="container mt-4 ">
            <div className="row">
              <div className="col-md-12 col-sm-12  packing-content">
                <div className="container cost-table-container">
                  <h2 className="text-center mb-4">
                    Packers and Movers Cost Calculation
                  </h2>
                  <table className="cost-table">
                    <thead>
                      <tr>
                        <th>Local Shifting</th>
                        <th>Approximate Cost</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1 BHK House shifting</td>
                        <td>Rs 5,000 to Rs 9,000</td>
                      </tr>
                      <tr>
                        <td>2 BHK House shifting</td>
                        <td>Rs 9,000 to Rs 12,000</td>
                      </tr>
                      <tr>
                        <td>3 BHK House shifting</td>
                        <td>Rs 12,000 to Rs 18,000</td>
                      </tr>
                      <tr>
                        <td>4 BHK House shifting</td>
                        <td>Rs 16,000 to Rs 22,000</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div
            className="service-main"
            id="Local-Household"
            ref={localHouseholdRef}
          >
            <div className="container ">
              <div className="col-md-12">
                <h2 className="title text-center">Our Services</h2>
                <figure className="wp-block-image size-large"></figure>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4 col-sm-12 pull-left">
                          <Link to="/LocalHouseholdShifting">
                            <div className="card">
                              <img
                                src={packing}
                                className="card-img-top"
                                alt="Packing"
                              />
                              <div className="card-body">
                                <h5 className="card-title">
                                  Local Household Shifting
                                </h5>
                                <p className="card-text">
                                  NMP Packers and movers specialize in local
                                  shifting services, ensuring a hassle-free
                                  experience for residents moving within the
                                  city. With a focus on reliability and
                                  efficiency, they offer packing, loading,
                                  transportation, and unpacking services
                                  tailored to meet individual needs. Their
                                  experienced team ensures careful handling of
                                  belongings to guarantee a smooth relocation
                                  process.
                                </p>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div className="col-md-4 col-sm-12 pull-left">
                          <Link to="/DomesticShiftingServices">
                            <div className="card">
                              <img
                                src={loading}
                                className="card-img-top"
                                alt="Loading"
                              />
                              <div className="card-body">
                                <h5 className="card-title">
                                  Domestic Shifting Services
                                </h5>
                                <p className="card-text">
                                  NMP Packers and movers specialize in seamless
                                  Domestic Shifting Services. With years of
                                  expertise, we ensure safe and efficient
                                  relocation of your belongings across India.
                                  Our trained professionals handle packing,
                                  loading, transportation, and unpacking with
                                  utmost care, ensuring your move is
                                  stress-free. Trust us for reliable and
                                  affordable domestic shifting solutions
                                  tailored to your needs.
                                </p>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div className="col-md-4 col-sm-12 pull-left">
                          <Link to="/HouseShiftingServices">
                            <div className="card">
                              <img
                                src={unloading}
                                className="card-img-top"
                                alt="Transportation"
                              />
                              <div className="card-body">
                                <h5 className="card-title">
                                  House Shifting Services
                                </h5>
                                <p className="card-text">
                                  House Shifting Services by Noida Packers and
                                  Movers offer seamless, efficient, and
                                  stress-free transitions for your business. Our
                                  experienced team ensures minimal disruption,
                                  handling everything from packing to unpacking.
                                  Trust us to manage your office relocation with
                                  utmost care and professionalism, guaranteeing
                                  a smooth move that allows your business to
                                  continue operations swiftly.
                                </p>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div className="col-md-4 col-sm-12 pull-left">
                          <Link to="/OfficeShiftingServices">
                            <div className="card">
                              <img
                                src={OfficeShifting}
                                className="card-img-top"
                                alt="Transportation"
                              />
                              <div className="card-body">
                                <h5 className="card-title">
                                  Office Shifting Services
                                </h5>
                                <p className="card-text">
                                  Looking for reliable office shifting services
                                  in Noida? NMP Packers and movers specialize in
                                  seamless office relocations, ensuring minimal
                                  downtime and maximum efficiency. Our
                                  professional team handles everything from
                                  packing, loading, transportation, to unpacking
                                  with utmost care. Trust us to move your office
                                  swiftly and securely. Contact us today for a
                                  hassle-free moving experience!
                                </p>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div className="col-md-4 col-sm-12 pull-left">
                          <Link to="/CarandBikeTransportation">
                            <div className="card">
                              <img
                                src={hour}
                                className="card-img-top"
                                alt="Transportation"
                              />
                              <div className="card-body">
                                <h5 className="card-title">
                                  Car & Bike Transportation
                                </h5>
                                <p className="card-text">
                                  NMP Packers and movers specialize in safe and
                                  reliable car and bike transportation services.
                                  With years of expertise, we ensure secure
                                  handling and timely delivery of vehicles to
                                  any destination. Our dedicated team uses
                                  advanced techniques for hassle-free
                                  relocation, prioritizing customer
                                  satisfaction. Trust NMP Packers and movers for
                                  your vehicle transportation needs with peace
                                  of mind.
                                </p>
                              </div>
                            </div>
                          </Link>
                        </div>
                        {/* <div className="col-md-4 col-sm-12 pull-left">
                          <Link to="/WarehouseServices">
                            <div className="card">
                              <img
                                src={warehouses}
                                className="card-img-top"
                                alt="Transportation"
                              />
                              <div className="card-body">
                                <h5 className="card-title"> Warehousing</h5>
                                <p className="card-text">
                                  NMP Packers and movers offer reliable
                                  warehouse services for secure storage of your
                                  belongings. Our warehouses are equipped with
                                  advanced security systems to ensure utmost
                                  safety. Whether you need short-term storage
                                  during relocation or long-term warehousing
                                  solutions, we provide flexible options
                                  tailored to your needs. Trust us for efficient
                                  handling and storage of your valuables.
                                </p>
                              </div>
                            </div>
                          </Link>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ClientReviews reviews={reviews} sliderSettings={sliderSettings} />
      </section>
    </>
  );
};

export default Hero;
